<template>
  <div class="items-wrapper__orders">
    <div class="grid-wrapper">
      <div class="col col-12">
        <div class="grid-info">
          <span class="grid-heading">
            <h1>{{ $t('Messages') }}</h1>
            <span class="entries">{{ NumberFormat(entries) }} {{ $t(`entr${entries == 1 ? 'y' : 'ies'}`) }}</span>
          </span>
          <div class="grid-actions">
            <div class="label" style="white-space: pre; text-align: right; margin-right: 20px;">
              <div class="label-text">{{ $t('SMS balance') }}</div>
              <b v-if="sms.ok" class="font">{{ `${sms.currency || ''} ` + CurrencyFormat(sms.credit) }}</b>
              <b v-else-if="sms.no_key" class="font red">{{ $t('No gateway key') }}</b>
              <b v-else-if="sms.no_secret" class="font red">{{ $t('No gateway secret') }}</b>
              <b v-else-if="sms.unauthorized" class="font red">{{ $t('Unauthorized') }}</b>
              <b v-else>{{ `${$t('Loading')}...` }}</b>
            </div>
            <div class="label">
              <a class="button" style="min-width: 115px;" @click.prevent="OpenModal('template')" href="">{{ $t('Templates') }}</a>
            </div>
            <div class="label">
              <a class="button" style="min-width: 115px;" @click.prevent="OpenModal('create')" href="">{{ $t('Create') }}</a>
            </div>
          </div>
          <div class="grid-search" style="margin-bottom: 11px;">
            <div class="search-fields" style="align-items: flex-end; max-width: 71%;">
              <input type="text" v-model="search.query" @change="Search" :placeholder="$t('Search by title')" spellcheck="false" style="width: 50%;">
              <div class="size-select" style="width: 160px; margin-top: -100%; margin-left: calc(3% + 0px);">
                <p class="grid-heading" style="font-weight: 600; color: #b3b8bd; padding-bottom: 2px;">{{ $t('Page size') }}</p>
                <v-select name="page-size-select" v-model="page.size" @input="QueryMessages" :options="page.sizes" :searchable="false" :clearable="false" />
              </div>
            </div>
          </div>
        </div>

        <table class="list table odd-even">
          <tbody>
            <tr :data-id="item.id" :class="['campaign-list__row clickable']" @mousedown="ClickOpen" @mousemove="ClickOpen" @mouseup="ClickOpen" v-for="item in list.items" :key="item.id">
              <td>
                <div class="flex-row">
                  {{ item.id }}
                </div>
              </td>
              <td>
                <div class="flex-row">
                  {{ NumberFormat(item.count_order_sms) }}
                </div>
              </td>
              <td>
                <div class="flex-row">
                  {{ item.template_title }}
                </div>
              </td>
              <td>
                <div class="flex-row">
                  {{ DateFormat(item.create_date) }}
                </div>
              </td>
              <td>
                <div class="flex-row">
                  {{ $t(TitleCase(item.res_user_name)) }}
                </div>
              </td>
            </tr>
          </tbody>
          <thead>
            <tr>
              <th>{{ $t('ID') }}</th>
              <th>{{ $t('Orders') }}</th>
              <th>{{ $t('Title') }}</th>
              <th>{{ $t('Created') }}</th>
              <th>{{ $t('Created by') }}</th>
            </tr>
          </thead>
        </table>

        <div class="grid-pagination shadow sticky bottom" :style="{marginTop: list.items.length && '2px'}">
          <div class="page-navigation">
            <div class="page-turn prev disabled" @click="PageController(false)"></div>
            <div class="page-number">
              <label class="page-number-current">
                <input type="number" min="1" :max="page.last" :value="page.current" @blur="PageNavigator" @keydown="PageNavigator">
                <span class="placeholder">{{NumberFormat(page.current)}}</span>
              </label>
              <span class="page-number-separator">/</span>
              <div class="page-number-last">{{NumberFormat(page.last)}}</div>
            </div>
            <div class="page-turn next" :class="{disabled: page.last == 1}" @click="PageController(true)"></div>
          </div>
        </div>
      </div>
    </div>

    <Modal modal="template" :value="modal.template.open" :title="$t('Templates')">
      <div id="address" v-if="modal.template.open">
        <div class="modal-header">
          <div class="modal-tabs__body-content" style="width: 100%; padding: 25px 0;">
            <form @submit.prevent ref="template_form">
              <button type="submit" ref="template_submit" hidden />
              <div class="flex-group">
                <div class="flex-row">
                  <div class="flex-column" style="max-width: unset; flex-grow: 1;">
                    <div class="label required">
                      <span class="label-text">{{ $t('Title') }}</span>
                      <input ref="template_title" class="v-input" type="text" :value="modal.template.title" required @keydown.enter="$event.target.blur()" @blur="FormatTitle($event, modal.template)" tabindex="1">
                    </div>
                  </div>
                </div>
                <div class="flex-row">
                  <div class="flex-column flex1">
                    <div class="label">
                      <div class="label-text" style="display: flex;">
                        <span class="required">{{ $t('Message') }}</span>
                        <span style="margin-left: auto;" v-html="Hyperlink({href: liquid_url, title: $t('Introduction to the Liquid template language'), target: '_blank', text: $t('Liquid')})" />
                      </div>
                      <textarea ref="template_text" class="v-textarea" style="resize: none; max-height: 144px;" :value="modal.template.text" required @input="FitToContent" @blur="FormatText($event, modal.template)" spellcheck="false" tabindex="2" />
                    </div>
                  </div>
                  <div class="flex-column flex1" style="max-width: 300px;">
                    <div class="label">
                      <span class="label-text">{{ $t('Variable') }}</span>
                      <v-select ref="template_args" v-model="modal.template.variable" :options="option.variable" @input="InsertLiquidVariable($event, modal.template, $refs.template_text)" :clearSearchOnSelect="true" :clearable="false">
                        <template v-slot:selected-option="option">
                          <span>{{ $t(option.label) }}</span>
                        </template>
                        <template slot="option" slot-scope="option">
                          <span>{{ $t(option.label) }}</span>
                        </template>
                      </v-select>
                    </div>
                  </div>
                </div>
                <div class="flex-row">
                  <div class="flex-column flex1">
                    <div class="label">
                      <div class="label-text" style="display: flex;">
                        <div>{{ $t('Preview') }}</div>
                        <div style="margin-left: auto;" :title="modal.template.preview.status.title">{{ modal.template.preview.status.string }}</div>
                      </div>
                      <textarea ref="template_preview" class="v-textarea" style="resize: none; max-height: 144px;" :value="modal.template.preview.text" readonly @input="FitToContent" spellcheck="false" tabindex="3" />
                    </div>
                  </div>
                  <div class="flex-column flex1" style="max-width: 298px;">
                    <div class="flex-row" style="height: 34px; margin-top: 23.19px;">
                      <button class="button icon chevron-left" style="min-width: 115px;" @click.prevent="PreviewOrderSMSTemplate(modal.template, $refs.template_preview, 'next')">{{ $t('Example') }}</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="modal-content scrollable">
          <div class="modal-tabs">
            <div class="modal-tabs__body">
              <div class="modal-tabs__body-content">
                <table class="list table odd-even">
                  <tbody>
                    <tr :data-id="template.id" :class="['list__row clickable', {selected: template.id == modal.template.id}]" @mousedown="GetTemplate" @mousemove="GetTemplate" @mouseup="GetTemplate" :key="template.id" v-for="template in modal.template.list">
                      <td class="template-id">
                        <div class="flex-row">
                          {{ template.id }}
                        </div>
                      </td>
                      <td class="template-title">
                        <div class="flex-row">
                          {{ template.title }}
                        </div>
                      </td>
                      <td @mouseenter="SetBackground" @mouseleave="SetBackground" class="actions">
                        <a @click.prevent="" href="" class="icon dots">
                          <ul class="template-actions">
                            <li><a @click.prevent="EditTemplate(template)" href="">{{ $t('Edit') }}</a></li>
                            <li><a @click.prevent="DeleteTemplate(template)" href="" class="error">{{ $t('Delete') }}</a></li>
                          </ul>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr class="list__actions">
                      <th class="template-id">{{ $t('ID') }}</th>
                      <th class="template-title">{{ $t('Title') }}</th>
                      <th class="edit" />
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <div class="button-container actions">
            <div class="button-group">
              <span v-if="modal.template.edit" class="separator right">
                <button class="button green" @click="UpdateTemplate" tabindex="3">{{ $t('Save') }}</button>
                <button class="button red" @click.prevent="ResetTemplateForm" tabindex="4">{{ $t('Cancel') }}</button>
              </span>
              <button class="button green" @click="CreateTemplate" tabindex="5">{{ $t(modal.template.id ? 'Copy' : 'Create') }}</button>
              <button v-if="modal.template.edit" class="button red" @click.prevent="DeleteTemplate" tabindex="6">{{ $t('Delete') }}</button>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <Modal modal="create" :value="modal.create.open" :title="$t('New message')">
      <div v-if="modal.create.open">
        <div class="modal-header grid-wrapper">
          <div class="modal-tabs__body-content" style="width: 100%; padding: 25px 0;">
            <div class="flex-group">
              <form @submit.prevent ref="message_form">
                <button type="submit" ref="message_submit" hidden />
                <div class="flex-group">
                  <div class="flex-row">
                    <div class="flex-column" style="max-width: unset; flex-grow: 1;">
                      <div class="label required" style="opacity: 1 !important;">
                        <span class="label-text">{{ $t('Title') }}</span>
                        <v-select ref="message_title" :options="modal.create.message.list.map(option => {option.label = option.title; return option})" :map-keydown="(map, vm) => ({13: (e) => {e.preventDefault(); vm.typeAheadSelect(); vm.searchEl.blur()}})" @search:blur="LoadTemplate($refs.message_title)" :clearSearchOnBlur="() => false" :clearable="true" :tabindex="1">
                          <template v-slot:selected-option="option">
                            <span>{{ $t(option.label) }}</span>
                          </template>
                          <template slot="option" slot-scope="option">
                            <span>{{ $t(option.label) }}</span>
                          </template>
                        </v-select>
                      </div>
                    </div>
                    <div class="flex-column" style="min-width: 300px;">
                      <div class="label required grid-wrapper">
                        <span class="label-text">{{ $t('Sending at') }}</span>
                        <div class="grid-info" style="margin: 0;">
                          <div class="grid-date" style="width: 100%;">
                            <div class="date-range flex-row">
                              <div class="input">
                                <VueCtkDateTimePicker id="send-at" v-model="modal.create.message.send_at" tabindex="2" required :locale="$i18n.locale.split('_')[0].replace(/no/, 'nb')" formatted="ddd, MMM D, YYYY, HH:mm" format="YYYY-MM-DD HH:mm:ss" :first-day-of-week="1" input-size="sm" label="" hint="" :range="false" :no-button-now="false" :no-shortcuts="true" :no-button="false" :auto-close="false" :noClearButton="false" right />
                              </div>
                            </div>
                          </div>
                        </div> 
                      </div>
                    </div>
                  </div>
                  <div class="flex-row">
                    <div class="flex-column flex1">
                      <div class="label">
                        <div class="label-text" style="display: flex;">
                          <span class="required">{{ $t('Message') }}</span>
                          <span style="margin-left: auto;" v-html="Hyperlink({href: liquid_url, title: $t('Introduction to the Liquid template language'), target: '_blank', text: $t('Liquid')})" tabindex="0" />
                        </div>
                        <textarea ref="message_text" class="v-textarea" style="resize: none; max-height: 144px;" :value="modal.create.message.text" required @input="FitToContent" @blur="FormatText($event, modal.create.message)" spellcheck="false" tabindex="3" />
                      </div>
                    </div>
                    <div class="flex-column flex1" style="max-width: 300px;">
                      <div class="label">
                        <span class="label-text">{{ $t('Variable') }}</span>
                        <v-select ref="message_args" v-model="modal.create.message.variable" :options="option.variable" @input="InsertLiquidVariable($event, modal.create.message, $refs.message_text)" :clearable="false">
                          <template v-slot:selected-option="option">
                            <span>{{ $t(option.label) }}</span>
                          </template>
                          <template slot="option" slot-scope="option">
                            <span>{{ $t(option.label) }}</span>
                          </template>
                        </v-select>
                      </div>
                    </div>
                  </div>
                  <div class="flex-row">
                    <div class="flex-column flex1">
                      <div class="label">
                        <div class="label-text" style="display: flex;">
                          <div>{{ $t('Preview') }}</div>
                          <div style="margin-left: auto;" :title="modal.create.message.preview.status.title">{{ modal.create.message.preview.status.string }}</div>
                        </div>
                        <textarea ref="message_preview" class="v-textarea" style="resize: none; max-height: 144px;" :value="modal.create.message.preview.text" readonly @input="FitToContent" spellcheck="false" tabindex="3" />
                      </div>
                    </div>
                    <div class="flex-column flex1" style="max-width: 298px;">
                      <div class="flex-row" style="height: 34px; margin-top: 23.19px;">
                        <button class="button icon chevron-left" style="min-width: 115px;" @click.prevent="PreviewOrderSMSTemplate(modal.create.message, $refs.message_preview, 'next')">{{ $t('Example') }}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="flex-group">
              <div class="flex-row">

                <div class="flex-column" style="width: 200px; flex: 0 1 auto;">
                  <div class="label">
                    <span class="label-text">{{ $t('Status') }}</span>
                    <v-select class="item-state-select" @input="ModalSearchByStatus" :options="option.status">
                      <template v-slot:selected-option="option">
                        <span :class="['state', option.code]">
                          {{ $t(option.label) }}
                        </span>
                      </template>
                      <template slot="option" slot-scope="option">
                        <span :class="['state', option.code]">
                          {{ $t(option.label) }}
                        </span>
                      </template>
                    </v-select>
                  </div>
                </div>

                <div class="flex-column" style="max-width: 200px; flex-grow: 1;">
                  <div class="label">
                    <span class="label-text">{{ $t('Language') }}</span>
                    <v-select name="item-language-select" @input="ModalSearchByLanguage" :options="Alphabetize(option.language.map(option => {option.label = LanguageName(option.language); return option}), 'label')">
                      <template v-slot:selected-option="option">
                        <div class="truncate">
                          <Flag :code="option.code" size="small" type="language" :title="CountryName(option.country)" />
                          <span>{{ option.label }}</span>
                        </div>
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="truncate">
                          <Flag :code="option.code" size="small" type="language" :title="CountryName(option.country)" />
                          <span>{{ option.label }}</span>
                        </div>
                      </template>
                    </v-select>
                  </div>
                </div>

                <div class="flex-column" style="max-width: 200px; flex-grow: 1;">
                  <div class="label">
                    <span class="label-text">{{ $t('Shipping labels') }}</span>
                      <div class="flex-row">
                        <div class="label">
                          <div class="v-wrapper border" style="height: 34px;">
                            <v-select class="item-state-select" v-model="modal.create.label_count.format" @input="ModalSearchByLabelCount" :options="option.label_count" style="width: 100px; flex-shrink: 0;" :searchable="false">
                              <template v-slot:selected-option="option">
                                <div :title="$t(option.title)" style="padding-left: 12px;">
                                  {{ option.label }}
                                </div>
                              </template>
                              <template slot="option" slot-scope="option">
                                <div :title="$t(option.title)">
                                  {{ option.label }}
                                </div>
                              </template>
                            </v-select>
                            <input type="search" pattern="[0-9]+" class="v-wrapper-input" style="flex-shrink: 1;" v-model="modal.create.label_count.value" @input="modal.create.label_count.value = ($event.target.value).replace(/\D|\s/g, '').trim()" @keydown.enter="$event.target.blur()" @blur="ModalSearchByLabelCount">
                          </div>
                        </div>
                      </div>
                  </div>
                </div>

                <div class="flex-column" style="min-width: 300px;">
                  <div class="label grid-wrapper">
                    <span class="label-text">{{ $t('Date range') }}</span>
                    <div class="grid-info" style="margin: 0;">
                      <div class="grid-date" style="width: 100%;">
                        <div class="date-range flex-row">
                          <div class="input">
                            <VueCtkDateTimePicker @input="ModalSearchByDate" id="search-date" v-model="modal.create.date" :locale="$i18n.locale.split('_')[0].replace(/no/, 'nb')" formatted="ddd, MMM D, YYYY" format="YYYY-MM-DD HH:mm:ss" :first-day-of-week="1" input-size="sm" label="" hint="" :range="true" :no-button-now="false" :no-shortcuts="true" :no-button="true" :auto-close="true" :noClearButton="true" />
                          </div>
                        </div>
                      </div>
                    </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-content scrollable">
          <div class="modal-tabs">
            <div class="modal-tabs__body">
              <div class="modal-tabs__body-content">
                <table class="list table odd-even">
                  <tbody>
                    <tr :data-id="item.id" :class="['campaign-list__row']" v-for="item in modal.create.list.items" :key="item.id">
                      <td class="label" style="display: table-cell;">
                        <div class="v-wrapper checkbox">
                          <label class="v-checkbox-label">
                            <input class="v-checkbox" type="checkbox" v-model="item.checked" @change="ToggleCheckbox($event, item)">
                            <span class="v-checkbox-toggle" />
                          </label>
                        </div>
                      </td>
                      <td>
                        <div class="flex-row" v-html="Hyperlink({href: itemOrder(item.id), target: '_blank', text: company.id + ':' + item.increment_id})" />
                      </td>
                      <td>
                        <div :class="['flex-row state', item.state]">
                          {{ $t(Capitalize(item.state)) }}
                        </div>
                      </td>
                      <td>
                        <div class="flex-row" style="align-items: center;">
                          <Flag :code="item.webshop.country" size="small" :title="LanguageName(item.webshop.language)" />
                          <span style="margin-left: 10px;" v-html="Hyperlink({href: item.webshop.url, target: '_blank', text: item.webshop.url.replace('https://', ''), title: LanguageName(item.webshop.language)})" />
                        </div>
                      </td>
                      <td>
                        <div class="flex-row">
                          {{ DateFormat(item.order_date) }}
                        </div>
                      </td>
                      <td>
                        <div class="flex-row" style="white-space: pre-line;">
                          {{ item.phone }}
                        </div>
                      </td>
                      <td>
                        <div class="flex-row">
                          {{ NumberFormat(item.label_count) }}
                        </div>
                      </td>
                      <td>
                        <div class="list-col trackingnumber" style="max-width: 250px;">
                          <div class="shipping">
                            <span :class="['courier-icon', item.agent.code]" :title="item.agent.name" />
                            <span class="shipping-method">{{ item.shipping_description }}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr>
                      <th class="label" style="display: table-cell; z-index: 3;">
                        <div class="v-wrapper checkbox">
                          <label class="v-checkbox-label">
                            <input class="v-checkbox" type="checkbox" @input.prevent="HandleDropdownTrigger" :disabled="!modal.create.list.items.length">
                            <div class="v-checkbox-toggle dropdown-trigger">
                              <div class="dropdown-list">
                                <div class="dropdown-item" v-show="modal.create.bulk.select.visible.includes(option.code)" :key="option.code" v-for="option of modal.create.bulk.select.options" @click="HandleDropdownOption(option)">{{ $t(option.label) }}</div>
                              </div>
                            </div>
                          </label>
                        </div>
                      </th>
                      <th>{{ $t('Order') + ' ID' }}</th>
                      <th>{{ $t('Status') }}</th>
                      <th>{{ $t('Webshop') }}</th>
                      <th>{{ $t('Order date') }}</th>
                      <th>{{ $t('Phone number') }}</th>
                      <th>{{ $t('Labels') }}</th>
                      <th>{{ $t('Shipping method') }}</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="height: 49px; display: flex; padding-top: 15px; justify-content: center;">
          <div class="entries" style="left: 0; position: absolute;">
            {{ NumberFormat(modal.create.bulk.select.items.checked.length) }} / {{ NumberFormat(modal.create.entries) }}
          </div>
          <div style="margin: 0 auto;">
            <div class="page-navigation">
              <div class="page-turn prev disabled" @click="ModalPageController(false)" />
              <div class="page-number">
                <label class="page-number-current">
                  <input type="number" min="1" :max="modal.create.page.last" :value="modal.create.page.current" @blur="ModalPageNavigator" @keydown="ModalPageNavigator">
                  <span class="placeholder">{{NumberFormat(modal.create.page.current)}}</span>
                </label>
                <span class="page-number-separator">/</span>
                <div class="page-number-last">{{NumberFormat(modal.create.page.last)}}</div>
              </div>
              <div class="page-turn next" :class="{disabled: modal.create.page.last == 1}" @click="ModalPageController(true)" />
            </div>
          </div>
          <button class="button green" style="right: 0; position: absolute; min-width: 115px;" @click="SendMessage">{{ $t('Send') }}</button>
        </div>
      </div>
    </Modal>

    <Modal modal="view" :value="modal.view.open" :title="$t('Message') + ' ' + modal.view.title">
      <div v-if="modal.view.open">
        <div class="modal-header grid-wrapper">
          <div class="modal-tabs__body-content" style="width: 100%; padding: 25px 0;">
            <div class="flex-group">
              <div class="flex-row">
                <div class="flex-column" style="max-width: unset; flex-grow: 1;">
                  <div class="label">
                    <span class="label-text">{{ $t('Title') }}</span>
                    <input ref="view_title" class="v-input" type="text" :value="modal.view.message.title" readonly>
                  </div>
                </div>
                <div class="flex-column" style="min-width: 300px;">
                  <div class="label grid-wrapper">
                    <span class="label-text">{{ $t('Sending at') }}</span>
                    <input type="text" class="v-input" :value="DateFormat(modal.view.message.send_at)" readonly>
                  </div>
                </div>
              </div>
              <div class="flex-row">
                <div class="flex-column flex1">
                  <div class="label">
                    <div class="label-text" style="display: flex;">
                      <span>{{ $t('Message') }}</span>
                    </div>
                    <textarea ref="view_text" class="v-textarea" style="resize: none; max-height: 144px;" :value="modal.view.message.text" readonly @input="FitToContent" spellcheck="false" />
                  </div>
                </div>
              </div>
              <div class="flex-row">
                <div class="flex-column flex1">
                  <div class="label">
                    <div class="label-text" style="display: flex;">
                      <div>{{ $t('Preview') }}</div>
                      <div style="margin-left: auto;">{{ modal.view.message.preview.status.string }}</div>
                    </div>
                    <textarea ref="view_preview" class="v-textarea" style="resize: none; max-height: 144px;" :value="modal.view.message.preview.text" readonly @input="FitToContent" spellcheck="false" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-content scrollable">
          <div class="modal-tabs">
            <div class="modal-tabs__body">
              <div class="modal-tabs__body-content">
                <table class="list table odd-even">
                  <tbody>
                    <tr :data-index="index" :class="['list__row clickable', {selected: index == modal.view.message.index}]" @mousedown="GetMessage" @mousemove="GetMessage" @mouseup="GetMessage" :key="index" v-for="(item, index) in modal.view.message.list">
                      <td>
                        <div class="flex-row" v-html="Hyperlink({href: itemOrder(item.order_overview_id), target: '_blank', text: $t('Open')})" />
                      </td>
                      <td>
                        <div class="flex-row">
                          {{ item.tlf }}
                        </div>
                      </td>
                      <td>
                        <div class="flex-row">
                          <span :class="['state', item.state.color]">
                            {{ $t(item.state.text) }}
                          </span>
                        </div>
                      </td>
                      <td v-if="modal.view.message.list.some(item => item.error_str)">
                        <div class="flex-row">
                          {{ item.error_str || '' }}
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <thead>
                    <tr class="list__actions">
                      <th>{{ $t('Order') }}</th>
                      <th>{{ $t('Phone number') }}</th>
                      <th>{{ $t('Status') }}</th>
                      <th v-if="modal.view.message.list.some(item => item.error_str)">
                        {{ $t('Error response') }}
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer" style="">
          
        </div>
      </div>
    </Modal>

    <div class="loading" v-if="loading">
      <div class="loading-element">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>

  </div>
</template>

<script>
  import { TableElementsBehaviour } from '@/mixins/TableElementsBehaviour';
  //import { FormElementsBehaviour }  from '@/mixins/FormElementsBehaviour';
  //import { Permissions }            from '@/helpers/Permissions';
  import { Config }                 from '@/helpers/Config';
  import { Tool }                   from '@/helpers/Tool';
  import { BPA }                    from '@/helpers/BPA';
  import Modal                      from '@/components/snippets/Modal';
  import Flag                       from '@/components/snippets/Flag';

  export default {
    name: 'itemsMessagesList',
    mixins: [
      TableElementsBehaviour, 
      //FormElementsBehaviour,
      Config,
      Tool,
      BPA
    ],
    components: {
      Modal,
      Flag
    },
    data () {
      return {
        dev: Config.dev,
        loading: false,
        clickables: [],
        cached: {},
        entries: 0,
        item: {},
        sms: {},
        page: {
          current: 1,
          number: 1,
          last: 1,
          size: 20,
          sizes: [10, 20, 30, 50, 100]
        },
        search: {
          input: null,
          query: '',
          initial: null
        },
        list: {
          items: [],
        },
        option: {
          status: [
            {code: 'holded', label: 'Holded'},
            {code: 'new', label: 'New'},
            {code: 'pick', label: 'Pick'},
            {code: 'awaiting', label: 'Awaiting'},
            {code: 'pack', label: 'Pack'},
            {code: 'manual', label: 'Manual'},
            {code: 'complete', label: 'Complete'},
            {code: 'canceled', label: 'Canceled'}
          ],
          language: [],
          label_count: [
            {code: 'eq', label: '=', title: 'Equal to'},
            {code: 'neq', label: '≠', title: 'Not equal to'},
            {code: 'lt', label: '<', title: 'Less than'},
            {code: 'gt', label: '>', title: 'Greater than'},
            {code: 'elt', label: '≤', title: 'Less than or equal to'},
            {code: 'egt', label: '≥', title: 'Greater than or equal to'}
          ],
          variable: [
            {code: 'first_name', label: 'First name'},
            {code: 'last_name', label: 'Last name'},
            {code: 'increment_id', label: 'Order number'},
            {code: 'shipping_description', label: 'Shipping method'},
            {code: 'language', label: 'Language'}
          ],
          shipping: []
        },
        courier: {},
        liquid_url: 'https://shopify.github.io/liquid/basics/introduction/',
        modal: {
          template: {
            open: false,
            edit: false,
            id: null,
            title: null,
            text: null,
            list: [],
            variable: null,
            preview: {
              index: 0,
              text: '',
              order: [],
              status: {},
              loading: false
            },
            selection: {
              start: 0, 
              end: 0
            },
            insert: []
          },
          create: {
            open: false,
            entries: 0,
            message: {
              id: null,
              title: null,
              text: null,
              list: [],
              variable: null,
              send_at: null,
              preview: {
                index: 0,
                text: '',
                order: [],
                status: {},
                loading: false
              },
              selection: {
                start: 0, 
                end: 0
              },
              insert: [],
            },
            page: {
              current: 1,
              number: 1,
              last: 1
            },
            search: {
              date: {
                start: '',
                end: ''
              },
              status: '',
              language: '',
              label_count: {}
            },
            date: {
              start: '',
              end: ''
            },
            label_count: {
              format: '',
              value: ''
            },
            list: {
              all: [],
              items: [],
              limit: 100,
              request: {}
            },
            bulk: {
              select: {
                options: [
                  {code: 'select_all', label: 'Select all'},
                  {code: 'deselect_all', label: 'Deselect all'},
                  {code: 'select_page', label: 'Select page'},
                  {code: 'deselect_page', label: 'Deselect page'}
                ],
                visible: ['select_all', 'select_page'],
                items: {
                  all: [],
                  checked: [],
                  unchecked: []
                },
                all: false
              }
            }
          },
          view: {
            open: false,
            title: '',
            message: {
              index: null,
              title: null,
              text: null,
              list: [],
              send_at: null,
              preview: {
                text: '',
                status: {}
              }
            }
          }
        }
      }
    },
    created() {
      let modal_data = this.CloneObject(this.modal);
      this.modal.reset = modal_name => this.modal[modal_name] = this.CloneObject(modal_data[modal_name]);
    },
    async mounted() {
      this.company = {
        id: BPA.company('id', this.main.company),
        name: BPA.company('name', this.main.company)
      };

      this.$eventHub.$on('CloseModal', (modal_name) => {
        let modal = this.modal[modal_name];
        if (!modal) return;
        this.modal.reset(modal_name);
        if (modal_name == 'template') {
          this.ResetTemplateForm();
        }
        if (modal_name == 'view') {
          this.cached.message = {};
          BPA.cache.session({name: this.$options.name, set: {message: {}}});
          for (let i = 0; i < this.clickables.length; i++) {
            if (this.clickables[i].classList.contains('selected')) {
              this.clickables[i].classList.remove('selected');
            }
          }
        }
        modal.open = false;
      });

      this.option.language = BPA.locale('options').map(option => {
        let label = option.label.split(' ')[0];
        let code = option.code.replace('-', '_');
        let language = code.split('_')[0].toLowerCase();
        if (/no/.test(code)) {
          code = code.replace('no', 'nb');
        }
        option.code = code;
        option.label = label;
        option.language = language;
        option.country = code.split('_')[1];
        return option;
      });
      this.option.language = [];
      let storage = BPA.util.storage;
      let languages = storage.get('languages');
      let company = BPA.util.GetCompany();
      if (!Object.keys(languages).length) {
        let locales = await this.GetLanguageOrderCount();
        languages[company] = [];
        for (let locale in locales) {
          let option = {code: locale};
          if (/nb/.test(locale)) {
            locale = locale.replace('nb', 'no');
          }
          let code = locale.split('_');
          option.label = this.LanguageName(code[0], 'en');
          option.language = code[0];
          option.country = code[1];
          this.option.language.push(option);
          languages[company].push(option);
        }
        storage.set('languages', languages);
      }
      this.option.language = languages[company];

      let courier = BPA.api.Couriers('GET');
      this.option.shipping = await this.GetShippingOptions();
      for (let option of this.option.shipping) {
        option.agent_name = courier[option.agent_code];
        this.courier[option.shipping_method] = {code: option.agent_code, name: option.agent_name};
      }

      this.search.input = document.querySelector('.grid-search input:first-of-type');

      await this.GetSMSAccountBalance();

      this.QueryMessages();
    },
    destroyed() {},
    computed: {
      main() {
        const main = {
          company: BPA.util.GetCompany(),
          view: document.querySelector('.content-scroll')
        }
        main.view.scroll = {top: 0};
        return main;
      },
      locale() {
        return this.$i18n.locale;
      }
    },
    watch: {
      locale() {
        //this.state = this.$t(this.Capitalize(this.search.state));
      }
    },
    methods: {
      ConsoleLog() {
        console.log(arguments[0])
      },
      Hyperlink(props = {}) {
        return Tool.Hyperlink(props);
      },
      DateFormat(date) {
        if (date) return Tool.DateFormat(date);
      },
      DateToISO(date) {
        if (date) return Tool.DateToISO(date);
      },
      DateToUTC(date) {
        if (date) return Tool.DateToUTC(date);
      },
      DateOnly(date) {
        date = date + ' 00:00:00';
        date = Tool.DateFormat(date).replace(Tool.TimeOnly(date), '');
        return date.replace(/,(?=[^,]*$)/, '');
      },
      NumberFormat(number) {
        return Tool.NumberFormat(number);
      },
      CurrencyFormat(number, minmax) {
        return Tool.CurrencyFormat(number, null, minmax);
      },
      CurrencyName(currency_code) {
        return Tool.CurrencyName(currency_code);
      },
      CountryName(country_code, locale) {
        return Tool.CountryName(country_code, locale);
      },
      LanguageName(country_code, locale) {
        return Tool.LanguageName(country_code, locale);
      },
      Alphabetize(list, prop) {
        return Tool.Alphabetize(list, prop);
      },
      Webshop(company_id, increment_id) {
        return Tool.Webshop(company_id, increment_id);
      },
      Empty(element) {
        while (element.firstChild) {
          element.removeChild(element.firstChild);
        }
      },
      CloneObject(object = {}) {
        return Tool.CloneObject(object);
      },
      Capitalize(string = '') {
        return String(Tool.Capitalize(string));
      },
      TitleCase(string = '') {
        return Tool.TitleCase(string);
      },
      Trim(e) {
        if (typeof e == 'string') {
          return String(e).trim();
        }
        if (typeof e == 'object' && e.target) {
          const element = e.target;
          const prop = 'value' || 'textContent';
          const text = element[prop];
          element[prop] = String(text).trim();
          return element[prop];
        }
      },
      itemOrder(order_id = '') {
        return window.location.origin + '/parcels/orders?id=' + order_id;
      },
      CapitalizeFirstLetter(e) {
        if (typeof e == 'string') {
          return String(e.charAt(0).toUpperCase() + e.slice(1)).replace(/\s\s+/g, ' ');
        }
        if (typeof e == 'object' && e.target) {
          const element = e.target;
          const prop = 'value' || 'textContent';
          const text = element[prop];
          element[prop] = String(text.charAt(0).toUpperCase() + text.slice(1)).replace(/\s\s+/g, ' ');
          return element[prop];
        }
      },
      RandomString(length = 5) {
        return Tool.RandomString(length);
      },
      FormatTitle(event, target = {}) {
        let input = event.target;
        let value = input.value.replace(/\s+/g, ' ');
        input.value =  this.Capitalize(value.trim());
        target.title = input.value;
      },
      FormatText(event, target = {}) {
        let input = event.target;
        let value = input.value;
        let lines = value.split('\n');
        let text = [];
        for (let i = 0; i < lines.length; i++) {
          let line = lines[i].replace(/\s+/g, ' ');
          if (i == 0) line = this.Capitalize(line);
          text.push(line);
        }
        input.value = text.join('\n');
        target.text = input.value;
        input.dispatchEvent(new Event('input'));
        target.selection = {
          start: input.selectionStart, 
          end: input.selectionEnd
        };
      },
      FitToContent(event) {
        let input = event.target;
        input.style.height = ''; 
        input.style.height = `${input.scrollHeight + 2}px`;
        //this.CalculateSizeAndSplit(input.value);
      },
      CalculateSizeAndSplit(text = '') {
        //let emoji = /\p{Emoji}/u; // https://stackoverflow.com/a/64396666
        let emoji = /[\p{Extended_Pictographic}\u{1F3FB}-\u{1F3FF}\u{1F9B0}-\u{1F9B3}]/ug;
        let emojis = text.match(emoji) || [];
        let length = text.length - emojis.length;
        let ucs2_encoding = emoji.test(text);
        let max = ucs2_encoding ? 70 : 160;
        let parts = Math.ceil(length / max) || 1;
        let limit = max * parts;
        //let bytes = Tool.ByteSize(text);
        if (parts > 1) {
          max -= ucs2_encoding ? 3 : 7;
          parts = Math.ceil(length / max);
          limit = max * parts;
        }
        let price = parts * 0.285; // Standard SMS rate
        let remaining = limit - (length % limit || limit) || limit;
        //let string = `${length}/${remaining} ${this.$t('of')} ${limit} ${this.$t('characters')} (${parts} ${this.$t('Message' + (parts != 1 ? 's' : '')).toLowerCase()} ${this.$t('at')} DKK ${this.CurrencyFormat(0.285, 3)}): ${this.$t('Total')} DKK ${this.CurrencyFormat(price, 3)}`;
        let string = `${length} / ${limit} ${this.$t('characters')} (${parts} ${this.$t('Message' + (parts != 1 ? 's' : '')).toLowerCase()}): DKK ${this.CurrencyFormat(price, 3)}`;
        let title = 'There are {n} remaining, before the message is split into another part';
        switch (this.$i18n.locale.split('_')[0]) {
          case 'sv': title = 'Det finns {n} tecken kvar innan meddelandet delas upp i en annan del'; break;
          case 'no': title = 'Det er {n} tegn igjen før meldingen deles i en annen del'; break;
          case 'de': title = 'Es verbleiben {n} Zeichen, bevor die Nachricht in einen anderen Teil aufgeteilt wird'; break;
          case 'da': title = 'Der er {n} tegn tilbage, før beskeden opdeles i en anden del';
        }
        title = title.replace('{n}', remaining);
        return {length, remaining, limit, max, parts, price, string, title, encoding: ucs2_encoding ? 'UCS2' : 'GSM 03.38'};
      },
      InsertLiquidVariable(option, target = {}, textarea) {
        let variable = `{{ ${option.code} }}`;
        let selection = target.selection;
        let start = selection.start;
        let end = selection.end;
        let text = target.text || '';
        let splice = {
          head: text.substring(0, start),
          body: text.substring(start, end),
          tail: text.substring(end, text.length)
        };
        if (/language/.test(variable)) {
          variable = `{% if language == "locale" %}{% endif %}`
        }
        text = splice.head + variable + splice.tail;
        let caret = (splice.head + variable).length;
        if (/language/.test(variable)) {
          caret = caret - 11;
        }
        target.selection = {start: caret, end: caret};
        target.insert.push(text);
        target.variable = null;
        target.text = text;
        this.$nextTick().then(() => {
          if (!textarea) return;
          textarea.selectionStart = caret;
          textarea.selectionEnd = caret;
          textarea.focus();
        });
      },
      async Search() {
        let search = this.search;
        let query = search.query;
        search.input.blur();
        search.query = query.replace(/\s\s+/g, ' ').trim();
        if (search.query == search.initial) return;
        this.page.current = 1;
        this.QueryMessages();
      }, 
      async QueryMessages() {
        let search = this.search;
        let query = search.query;
        let page = this.page;
        let list = await this.GetOrderSMSGroupList({
          query, page_offset: page.current, page_size: page.size
        });
        if (!list) {
          this.entries = 0;
          this.list.items = [];
          this.page.current = 1;
          this.page.number = 1;
          this.page.last = 1;
          return;
        }
        let res_user_ids = {};
        for (let user of list.res_user_ids) {
          res_user_ids[user.id] = user.name;
        }
        for (let item of list.items) {
          item.res_user_name = res_user_ids[item.res_user_id];
        }
        this.entries = list.item_count;
        this.list.items = list.items;
        this.page.last = list.page_count || 1;
        this.page.current = (list.page_offset || 0) + 1;
        this.list.items.sort((a, b) => b.id - a.id);
        BPA.cache.local({name: this.$options.name, set: {page: this.page, search: this.search}});
        await this.$nextTick().then(() => {
          this.clickables = document.querySelectorAll('.clickable');
          /*
          if (this.cached.customer && Object.keys(this.cached.customer).length) {
            this.Edit(this.cached.customer.id);
          } else if (this.clickables.length == 1 && (this.clipboard || {}).search) {
            this.Edit(this.clickables[0].dataset.id);
          }
          */
          this.SetPageJumpWidth();
        });
      },
      async ModalSearchByStatus(option) {
        this.modal.create.search.status = option ? option.code : '';
        this.modal.create.page.current = 1;
        await this.ResetBulk();
        this.ModalQueryOrders();
      },
      async ModalSearchByLanguage(option) {
        this.modal.create.search.language = option ? option.code : '';
        this.modal.create.page.current = 1;
        await this.ResetBulk();
        this.ModalQueryOrders();
      },
      async ModalSearchByLabelCount() {
        let search = this.modal.create.search;
        let label_count = this.CloneObject(this.modal.create.label_count);
        if (Object.values(label_count).filter(e => e).length == 2) {
          label_count.format = label_count.format.code;
          label_count = {[label_count.format]: Number(label_count.value)};
        } else {
          label_count = {};
        }
        if (JSON.stringify(search.label_count) == JSON.stringify(label_count)) return;
        search.label_count = label_count;
        this.modal.create.page.current = 1;
        await this.ResetBulk();
        this.ModalQueryOrders();
      },
      async ModalSearchByDate(date) {
        let page = this.modal.create.page;
        let search = this.modal.create.search;
        const ModalQueryOrders = async () => {
          page.current = 1;
          await this.ResetBulk();
          this.ModalQueryOrders();
        }
        if (!date) {
          let clear = {start: '', end: ''};
          search.date = clear;
          this.date = clear;
          return ModalQueryOrders();
        }
        search.date.start = this.DateToUTC(date.start);
        search.date.end = this.DateToUTC(date.end);
        if (search.date.start) ModalQueryOrders();
      },
      async ModalQueryOrders(initial) {
        let modal = this.modal.create;
        let page = modal.page;
        let search = modal.search;
        let request = {
          offset: page.current - 1,
          from_date: search.date.start
        };
        if (search.status) {
          request.status = search.status;
        }
        if (search.language) {
          request.language = search.language;
        }
        if (Object.keys(search.label_count).length) {
          request.label_count = JSON.stringify(search.label_count).replace(/"|\{|\}/g, '');
        }
        if (search.date.end) request.to_date = search.date.end;
        this.loading = true;
        let list = await this.GetOrderSMSOrderList(request);
        if (!list) {
          if (!initial) this.loading = false;
          modal.list.request = {};
          modal.list.items = [];
          modal.entries = 0;
          page.current = 1;
          page.number = 1;
          page.last = 1;
          return;
        }
        page.last = list.page_count || 1;
        page.current = request.offset + 1;
        delete request.offset;
        /*
        if (JSON.stringify(request) != JSON.stringify(modal.list.request)) {
          modal.list.all = await this.GetAllOrderItems();
          modal.entries = modal.list.all.length;
        }
        */
        modal.entries = list.item_count;
        modal.list.request = request;
        let items = list.items;
        let courier = this.courier;
        let select = modal.bulk.select;
        let checked = select.items.checked;
        let unchecked = select.items.unchecked;
        for (let item of items) {
          if (select.all) {
            if (!unchecked.includes(item.id)) {
              if (!checked.includes(item.id)) {
                for (let i = 0; i < checked.length; i++) {
                  if (!checked[i]) {
                    checked.splice(i, 1); 
                    break;
                  }
                }
                checked.push(item.id);
              }
              item.checked = true;
            }
          } else {
            item.checked = checked.includes(item.id);
          }
          item.webshop = this.Webshop(this.company.id, item.increment_id);
          if (!item.webshop.language) {
            let language = item.language.split('_');
            let country = language[1].toLowerCase();
            item.webshop.language = language[1];
            item.webshop.country = country;
            item.webshop.url += country;
          }
          item.agent = courier[item.shipping_description];
          item.phone = item.tlf_shipping || item.tlf_billing || '-';
          if (item.tlf_shipping != item.tlf_billing) {
            item.phone = item.tlf_billing + '\n' + item.tlf_shipping;
          }
        }
        if (!items.some(item => item.checked)) {
          let index = select.visible.indexOf('deselect_page');
          if (index > -1) select.visible.splice(index, 1);
          if (!select.visible.includes('select_page')) {
            select.visible.push('select_page');
          }
        } else {
          let checked = items.filter(item => item.checked);
          if (checked.length == items.length) {
            let index = select.visible.indexOf('select_page');
            if (index > -1) select.visible.splice(index, 1);
          }
          if (!select.visible.includes('deselect_page')) {
            select.visible.push('deselect_page');
          }
        }
        select.visible = [...new Set(select.visible)];
        for (let type of ['checked', 'unchecked']) {
          select.items[type].sort();
        }
        items.sort((a, b) => new Date(b.order_date) - new Date(a.order_date));
        modal.list.items = items;
        this.$nextTick().then(async () => {
          this.ModalSetPageJumpWidth();
          if (!initial) this.loading = false;
        });
      },
      async GetAllOrderItems() {
        let modal = this.modal.create;
        let last_page = modal.page.last;
        let search = modal.search;
        let order_items = [];
        let request = {
          from_date: search.date.start
        };
        if (search.status) {
          request.status = search.status;
        }
        if (search.language) {
          request.language = search.language;
        }
        if (Object.keys(search.label_count).length) {
          request.label_count = JSON.stringify(search.label_count).replace(/"|\{|\}/g, '');
        }
        if (search.date.end) request.to_date = search.date.end;
        for (request.offset = 0; request.offset < last_page; request.offset++) {
          let list = await this.GetOrderSMSOrderList(request);
          for (let item of list.items) order_items.push(item);
        }
        return order_items;
      },
      async OpenModal(modal_name) {
        this.loading = true;
        const firstDiff = (a, b) => {
          let i = 0;
          for (i; i < a.length; i++) {
            if (a[i] != b[i]) return i;
          }
          return i < b.length ? i : -1;
        }
        if (modal_name == 'template') {
          let target = this.modal.template;
          await this.GetTemplateList(target);
          this.$nextTick().then(() => {
            let textarea = this.$refs.template_text;
            if (!textarea) return;
            target.preview.status = this.CalculateSizeAndSplit();
            textarea.onkeypress = (e) => {
              let z_key = 26; // Ctrl+Z
              if (e.ctrlKey && e.which == z_key) {
                if (target.insert.length > 1) {
                  let insert = target.insert;
                  let undo = insert.pop();
                  target.text = insert.slice(-1)[0];
                  let caret = firstDiff(target.text, undo);
                  target.selection = {start: caret, end: caret};
                  textarea.selectionStart = caret;
                  textarea.selectionEnd = caret;
                  textarea.blur();
                  textarea.focus();
                }
              }
            }
            this.loading = false;
          });
        }
        if (modal_name == 'create') {
          let target = this.modal.create;
          await this.GetTemplateList(target.message);
          let today = new Date();
          let start_date = new Date(today.setDate(today.getDate()/* - 14*/));
          start_date.setHours(0, 0, 0);
          target.date.start = this.DateToISO(start_date);
          target.search.date.start = this.DateToUTC(start_date);
          target.message.preview.status = this.CalculateSizeAndSplit();
          await this.ModalQueryOrders(true);
          this.$nextTick().then(() => {
            this.ModalSetPageJumpWidth();
            let message = this.modal.create.message;
            let title_select = this.$refs.message_title;
            let title_input = title_select.searchEl;
            let clear_button = title_select.$refs.clearButton;
            clear_button.onclick = () => {
              title_input.required = true;
              message.id = null;
            }
            title_input.required = true;
            this.loading = false;
          });
        }
        if (modal_name == 'view') {
          this.$nextTick().then(() => {
            let view_text = this.$refs.view_text;
            view_text.dispatchEvent(new Event('input'));
            this.loading = false;
          });
        }
        this.modal[modal_name].open = true;
      },
      async GetTemplateList(target = {}) {
        target.list = await this.GetOrderSMSTemplateList();
      },
      async GetTemplate(event) {
        if (!event) return;
        let row = {};
        let elm = event.target;
        if (elm.localName != 'tr') {
          row = elm.closest('tr');
        }
        let filter = ['actions', 'icon dots'];
        const mousedown = (state) => {
          if (state === true || state === false) {
            row.mousedown = state;
          } else {
            return row.mousedown;
          }
        }
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i] != row) {
            this.clickables[i].classList.remove('hover', 'selected');
          }
        }
        if (elm.localName == 'a' || elm.parentElement.localName == 'a') return;
        if (filter.some(x => new RegExp(x).test(elm.className))) return;
        if (event.type == 'mousedown') {
          mousedown(event.which == 1);
        }
        if (event.type == 'mousemove') {
          mousedown(false);
        }
        if (event.type == 'mouseup') {
          mousedown() && this.EditTemplate(row.dataset.id);
          mousedown(false);
        }
      },
      async LoadTemplate(component = {}) {
        if (!('search' in component)) return;
        let search = component.search.replace(/\s+/g, ' ').trim();
        let message = this.modal.create.message;
        component.search = search;
        if (search) {
          message.id = null;
          component.clearSelection();
        }
        if (component.selectedValue.length) {
          let option = component.selectedValue[0];
          let template_id = option.id;
          let list = message.list;
          if (template_id == message.id) return;
          for (let i = 0; i < list.length; i++) {
            let item = list[i];
            if (item.id == template_id) {
              this.loading = true;
              item = await this.GetOrderSMSTemplate(template_id);
              if (item instanceof Object && 'text' in item) {
                delete item.res_company_id;
                list[i] = item;
              }
              this.loading = false;
              for (let key in item) {
                message[key] = item[key];
              }
              message.insert.push(message.text || '');
              break;
            }
          }
          this.$nextTick().then(() => {
            let textarea = null;
            for (let area of ['preview', 'text']) {
              textarea = this.$refs[`message_${area}`];
              if (!textarea) return;
              textarea.dispatchEvent(new Event('input'));
            }
            let length = textarea.value.length;
            message.selection = {start: length, end: length};
            textarea.selectionStart = length;
            //textarea.focus();
          });
          component.searchEl.required = false;
        } else {
          component.searchEl.required = true;
        }
      },
      async SendMessage() {
        let items = [];
        let create = this.modal.create;
        let message = create.message;
        let select = create.bulk.select;
        let checked = select.items.checked;
        let sms_send_at = this.DateToUTC(message.send_at);
        let required = [...document.querySelectorAll('[required]')];
        let invalid_input = required.find(input => !input.checkValidity() || !input.value);
        let datetimepicker_id = 'send-at-input';
        let invalid_items = [];
        if (invalid_input) {
          if (invalid_input.id == datetimepicker_id) {
            setTimeout(() => invalid_input.focus());
          } else {
            this.$refs.message_submit.click();
          }
          return;
        }
        if (!checked.length) {
          return this.$eventHub.$emit('ShowMessages', {
            message: 'No orders have been selected',
            type: 'warning',
            hide: 2000
          });
        }
        if (sms_send_at <= this.DateToUTC(new Date())) {
          let datetimepicker = required.find(item => item.id == datetimepicker_id);
          if (datetimepicker) setTimeout(() => datetimepicker.focus());
          return this.$eventHub.$emit('ShowMessages', {
            message: 'The time of dispatch must be in the future',
            type: 'warning',
            hide: 2000
          });
        }
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, send it',
          disapprove: 'No',
          message: 'Sends message to phone number of selected orders.',
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async approve => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          if (!create.list.all.length) {
            create.list.all = await this.GetAllOrderItems();
          }
          //await this.GetOrderSMSOrderListByIds(checked);
          for (let item of create.list.all) {
            if (checked.includes(item.id)) {
              let phone = item.tlf_shipping || item.tlf_billing;
              if (/^\+\d{2,}\d{8,}$/.test(phone) && /^(?!.*(\d)\1{7}).*$/.test(phone)) {
                items.push({order_id: item.id, tlf: phone, sms_send_at});
              } else {
                invalid_items.push(item);
              }              
            }            
          }
          this.loading = true;
          for (let group of Tool.Group(items, create.list.limit)) {
            if (!await this.CreateOrderSMSMessage({body: {
              title: message.title, mgs: message.text, orders: group
            }})) return this.loading = false;
          }
          this.$eventHub.$emit('ShowMessages', {
            message: 'Message successfully created',
            type: 'success',
            hide: 2000
          });
          await this.QueryMessages();
          this.$eventHub.$emit('CloseModal', 'create');
          this.loading = false;
          if (invalid_items.length) {
            console.log(this.CloneObject(invalid_items))
          }
        });
      },
      async EditTemplate(template_id) {
        if (template_id instanceof Object) {
          template_id = template_id.id;
        }
        if (!template_id) {
          return console.warn('Template ID missing!');
        }
        let template = this.modal.template;
        let list = template.list;
        if (template_id == template.id) return;
        for (let i = 0; i < list.length; i++) {
          let item = list[i];
          if (item.id == template_id) {
            this.loading = true;
            item = await this.GetOrderSMSTemplate(template_id);
            if (item instanceof Object && 'text' in item) {
              delete item.res_company_id;
              list[i] = item;
            }
            this.loading = false;
            for (let key in item) {
              template[key] = item[key];
            }
            template.insert.push(template.text || '');
            template.edit = true;
            break;
          }
        }
        this.$nextTick().then(() => {
          let textarea = null;
          for (let area of ['preview', 'text']) {
            textarea = this.$refs[`template_${area}`];
            if (!textarea) return;
            textarea.dispatchEvent(new Event('input'));
          }
          let length = textarea.value.length;
          template.selection = {start: length, end: length};
          textarea.selectionStart = length;
          //textarea.focus();
        });
      },
      async ValidateTemplateForm() {
        return await new Promise(resolve => {
          let inputs = [...this.$refs.template_form.elements];
          inputs = inputs.filter(input => /v-(input|textarea)/.test(input.className));
          resolve(inputs.filter(input => input.checkValidity()).length == inputs.length);
        });
      },
      async ResetTemplateForm() {
        return await new Promise(resolve => {
          let template = this.modal.template;
          let preview = template.preview;
          template.edit = false;
          for (let key of ['id', 'title', 'text', 'variable']) {
            template[key] = null;
          }
          preview.index = 0;
          preview.text = '';
          preview.order = [];
          template.insert = [];
          preview.status = this.CalculateSizeAndSplit();
          if (template.open) {
            this.$nextTick().then(() => {
              for (let area of ['preview', 'text']) {
                let textarea = this.$refs[`template_${area}`];
                if (!textarea) return resolve(true);
                textarea.dispatchEvent(new Event('input'));
              }
              resolve(true);
            });
          } else {
            template.list = [];
            resolve(true);
          }
        });
      },
      async CreateTemplate() {
        if (!await this.ValidateTemplateForm()) {
          return this.$refs.template_submit.click();
        }
        let template = this.modal.template;
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, create it',
          disapprove: 'No',
          message: `Creates a new SMS template${template.edit ? ` from ID ${template.id}` : ''}.`,
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          await this.CreateOrderSMSTemplate({body: {
            title: template.title,
            text: template.text
          }}).then(async template_id => {
            if (!template_id) return;
            await this.GetTemplateList(template);
            await this.ResetTemplateForm();
            this.$eventHub.$emit('ShowMessages', {
              message: `SMS template ID ${template_id} successfully created`,
              type: 'success',
              hide: 2000
            });
          });
          this.loading = false;
        });
      },
      async UpdateTemplate() {
        if (!await this.ValidateTemplateForm()) return;
        let template_changed = false;
        let template = this.modal.template;
        for (let item of template.list) {
          if (item.id == template.id) {
            for (let key of ['title', 'text']) {
              if (item[key] != template[key]) {
                template_changed = true;
                break;
              }
            }
            break;
          }
        }
        if (!template_changed) {
          this.$eventHub.$emit('ShowMessages', {
            message: `No changes made to SMS template ID ${template.id}`,
            type: 'warning',
            hide: 2000
          });
          return this.ResetTemplateForm();
        }
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, update it',
          disapprove: 'No',
          message: `Updates SMS template ID ${template.id}.`,
          type: 'success'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          let template = this.modal.template;
          let template_id = template.id;
          await this.UpdateOrderSMSTemplate({
            template_id, body: {
              title: template.title,
              text: template.text
            }
          }).then(async () => {
            await this.GetTemplateList();
            await this.ResetTemplateForm();
            this.$eventHub.$emit('ShowMessages', {
              message: `SMS template ID ${template_id} successfully updated`,
              type: 'success',
              hide: 2000
            });
          });
          this.loading = false;
        });
      },
      async DeleteTemplate(template = {}) {
        let modal_template = this.modal.template;
        let template_id = template.id || modal_template.id;
        if (!template_id) return console.warn('Template ID missing!');
        this.$eventHub.$emit('ValidateModalStart', {
          approve: 'Yes, delete it',
          disapprove: 'No',
          message: `Deletes SMS template ID ${template_id}.`,
          type: 'danger'
        });
        this.$eventHub.$on('ValidateModalStop', async (approve) => {
          this.$eventHub.$off('ValidateModalStop');
          if (!approve) return;
          this.loading = true;
          await this.DeleteOrderSMSTemplate(template_id);
          await this.GetTemplateList(modal_template);
          await this.ResetTemplateForm();          
          this.$eventHub.$emit('ShowMessages', {
            message: `SMS template ID ${template_id} successfully deleted`,
            type: 'success',
            hide: 2000
          });
          this.loading = false;
        });
      },
      async GetOrderSMSOrderList(request = {}) {
        if (!Object.keys(request).length) return;
        return await BPA.api.GetOrderSMSOrderList(this.CloneObject(request)).then(response => {
          return BPA.api.response({response, return: 'json', error: (message) => {
            let teapot = response?.status == 418;
            let error = {message, type: teapot ? 'warning' : 'error'};
            teapot ? error.hide = 2000 : error.close = true;
            this.$eventHub.$emit('ShowMessages', error);
            return response;            
          }});
        }).then(response => {
          if (!response.ok) return;
          return response.result || {};
        }).catch(e => e);
      },
      async GetOrderSMSOrderListByIds(order_ids = []) {
        if (!order_ids.length) return;
        return await BPA.api.GetOrderSMSOrderListByIds(order_ids).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          console.log(response.result)
          return response.result;
        }).catch(e => e);
      },
      async PreviewOrderSMSTemplate(target = {}, textarea, direction = '') {
        let preview = target.preview;
        let index = preview.index;
        let create_preview_text = (order = {}) => {
          let text = target.text;
          let args = order.args || {};
          let condition = new RegExp('({% if (.*) %})((.|\\n)*?)({% endif %})', 'gm');
          for (let match of text.match(condition) || []) {
            let comparison = match.replace(condition, '$2').split(' ');
            if (comparison[0] == 'language' && comparison.length == 3) {
              let variable = args[comparison[0]];
              let operator = comparison[1];
              let parameter = comparison[2].replace(/['"]/g, '');
              if (!eval(`"${variable}" ${operator} "${parameter}"`)) {
                text = text.replace(match, '');
              }
            }
          }
          for (let key in args) {
            let object = new RegExp(`{{(\\s?)${key}(\\s?)}}`, 'g');
            text = text.replace(object, args[key]);            
          }
          let tags = new RegExp('{%(.*?)%}', 'g');
          text = text.replace(tags, '');
          this.$nextTick().then(() => {
            this.FitToContent({target: textarea});
            preview.status = this.CalculateSizeAndSplit(text);
          });
          preview.text = text;
        }
        if (preview.loading) return;
        if (!target.text) {
          target.text = '';
          create_preview_text();
          return preview.index = 0;
        }
        if (direction == 'prev') index--;
        if (direction == 'next') index++;
        if (index < 0) return;
        if (preview.order[index]) {
          create_preview_text(preview.order[index]);
          return preview.index = index;
        }
        preview.loading = true;
        let request = {index, body: {template: target.title || ''}};
        return await BPA.api.PreviewOrderSMSTemplate(request).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          preview.loading = false;
          if (!response.ok || !response.result) return;
          create_preview_text(response.result);
          preview.order.push(response.result);
          preview.index = index;
        }).catch(e => e);
      },
      async GetLanguageOrderCount(company_code) {
        return await BPA.api.GetLanguageOrderCount(company_code).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => response.result || {}).catch(e => e);
      },
      async GetOrderSMSTemplateList() {
        return await BPA.api.GetOrderSMSTemplateList().then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          let list = response.result || [];
          return list.sort((a, b) => b.id - a.id);
        }).catch(e => e);
      },
      async GetOrderSMSTemplate(template_id = '') {
        if (!template_id) return;
        return await BPA.api.GetOrderSMSTemplate(template_id).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok || !response.result) return;
          return response.result;
        }).catch(e => e);
      },
      async CreateOrderSMSTemplate(request = {}) {
        if (!Object.keys(request).length) return;
        return await BPA.api.CreateOrderSMSTemplate(request).then(response => {
          return BPA.api.response({response, return: 'text'});
        }).then(response => {
          if (!response.ok) return;
          return response.result;
        }).catch(e => e);
      },
      async UpdateOrderSMSTemplate(request = {}) {
        if (!Object.keys(request).length) return;
        return await BPA.api.UpdateOrderSMSTemplate(request).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok || !response.result) return;
          return response.result;
        }).catch(e => e);
      },
      async DeleteOrderSMSTemplate(template_id = '') {
        if (!template_id) return;
        return await BPA.api.DeleteOrderSMSTemplate(template_id).then(response => {
          return BPA.api.response({response});
        }).then(response => response.ok).catch(e => e);
      },
      async GetShippingOptions() {
        return await BPA.api.GetShippingOptions(this.main.company).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => response.result || []).catch(e => e);
      },
      async GetSMSAccountBalance(sms = {}) {
        return await BPA.api.GetSMSAccountBalance().then(response => {
          return BPA.api.response({response, return: 'json', error: (message) => {
            if (response && response.status == 424) {
              if (/key/i.test(message)) sms.no_key = true;
              if (/secret/i.test(message)) sms.no_secret = true;
              if (/unauthorized/i.test(message)) sms.unauthorized = true;
              return response;
            }
            this.$eventHub.$emit('ShowMessages', {
              message: message,
              type: 'error',
              close: true
            });
          }});
        }).then(response => {
          if (response.ok) sms.ok = true;
          return this.sms = {...response.result || {}, ...sms};
        });
      },
      async GetOrderSMSGroupList(request = {}) {
        return await BPA.api.GetOrderSMSGroupList(request).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          return response.result;
        }).catch(e => e);
      },
      async GetOrderSMSGroup(group_id = '') {
        return await BPA.api.GetOrderSMSGroup(group_id).then(response => {
          return BPA.api.response({response, return: 'json'});
        }).then(response => {
          if (!response.ok) return;
          return response.result;
        }).catch(e => e);
      },
      async CreateOrderSMSMessage(request = {}) {
        if (!Object.keys(request).length) return;
        return await BPA.api.CreateOrderSMSMessage(request).then(response => {
          return BPA.api.response({response, return: 'text'});
        }).then(response => {
          if (!response.ok) return;
          return response.result;
        }).catch(e => e);
      },
      async ViewMessage(group_id) {
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i].dataset.id == group_id) {
            this.clickables[i].classList.add('selected');
            if (this.cached.message && Object.keys(this.cached.message).length) {
              this.clickables[i].scrollIntoView({
                behavior: 'auto',
                block: 'center',
                inline: 'center'
              });
            }
            break;
          }
        }
        let modal = this.modal.view;
        let group = await this.GetOrderSMSGroup(group_id);
        let message = group.order_sms_items[0];
        modal.title = `<span>#${group.id}: ${group.create_by}</span><span>${this.DateFormat(group.create_date)}</span>`;
        modal.message.title = group.template_title;
        modal.message.text = group.template_text;
        modal.message.send_at = message.sms_send_at;
        for (let item of group.order_sms_items) {
          item.state = {color: '', text: ''};
          for (let state of ['processed', 'send', 'error']) {
            if (item['sms_' + state]) {
              item.state.text = this.Capitalize(state == 'send' ? 'sent' : state);
              item.state.color = state == 'processed' ? 'yellow' : state == 'send' ? 'green' : 'red';
            }
          }
        }
        modal.message.list = group.order_sms_items;
        this.OpenModal('view');
      },
      LoadMessage(index) {
        if (isNaN(index)) return;
        let modal = this.modal.view;
        let message = modal.message;
        let preview = this.$refs.view_preview;
        let text = message.list[index].sms_text;
        message.index = index;
        message.preview.text = text;
        message.preview.status = this.CalculateSizeAndSplit(text);
        setTimeout(() => preview.dispatchEvent(new Event('input')));
      },
      GetMessage(e) {
        if (!e) return;
        let row = {};
        let elm = e.target;
        if (elm.localName != 'tr') row = elm.closest('tr');
        const filter = ['actions', 'icon dots'];
        const mousedown = (state) => {
          if (state === true || state === false) {
            row.mousedown = state;
          } else {
            return row.mousedown;
          }
        }
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i] != row) {
            this.clickables[i].classList.remove('hover', 'selected');
          }
        }
        if (elm.type == 'checkbox') return;
        if (/label|checkbox/.test(elm.className)) return;
        if (elm.localName == 'a' || elm.parentElement.localName == 'a') return;
        if (filter.some(x => new RegExp(x).test(elm.className))) return;
        if (e.type == 'mousedown') {
          mousedown(e.which == 1);
        }
        if (e.type == 'mousemove') {
          mousedown(false);
        }
        if (e.type == 'mouseup') {
          if (mousedown()) {
            this.LoadMessage(row.dataset.index);
          }
          mousedown(false);
        }
      },
      ClickOpen(e) {
        if (!e) return;
        let row = {};
        let elm = e.target;
        if (elm.localName != 'tr') row = elm.closest('tr');
        const filter = ['actions', 'icon dots'];
        const mousedown = (state) => {
          if (state === true || state === false) {
            row.mousedown = state;
          } else {
            return row.mousedown;
          }
        }
        for (let i = 0; i < this.clickables.length; i++) {
          if (this.clickables[i] != row) {
            this.clickables[i].classList.remove('hover', 'selected');
          }
        }
        if (elm.type == 'checkbox') return;
        if (/label|checkbox/.test(elm.className)) return;
        if (elm.localName == 'a' || elm.parentElement.localName == 'a') return;
        if (filter.some(x => new RegExp(x).test(elm.className))) return;
        if (e.type == 'mousedown') {
          mousedown(e.which == 1);
        }
        if (e.type == 'mousemove') {
          mousedown(false);
        }
        if (e.type == 'mouseup') {
          if (mousedown()) {
            this.ViewMessage(row.dataset.id);
          }
          mousedown(false);
        }
      },
      PageController(next_page) {
        let load_page;
        const page = {};
        document.querySelectorAll('.page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        if (next_page) {
          if (this.page.current != this.page.last) {
            this.page.current++;
            load_page = true;
            if (this.page.current == this.page.last) {
              page.next.classList.add('disabled');
            }
            page.prev.classList.remove('disabled');
          }
        } else {
          if (this.page.current > 1) {
            this.page.current--;
            load_page = true;
            if (this.page.current == 1) {
              page.prev.classList.add('disabled');
            }
            page.next.classList.remove('disabled');
          }
        }
        if (load_page) {
          this.ModalQueryOrders();
        }
      },
      PageNavigator(e) {
        const page = {
          event: e.type,
          input: e.target,
          last: Number(e.target.max),
          first: Number(e.target.min),
          number: Number(e.target.value)
        }
        const within_limits = (n) => {
          return n >= page.first && n <= page.last;
        }
        const set_page_number = (n) => {
          this.page.number = n || page.number;
          page.number = Number(this.page.number);
        }
        document.querySelectorAll('.page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        set_page_number();
        if (page.event == 'keydown') {
          if (e.key == 'Enter') {
            page.input.blur();
          }
        }
        if (page.event == 'blur') {
          ['prev', 'next'].map(arrow => {
            page[arrow] && page[arrow].classList.remove('disabled');
          });
          if (page.number <= page.first) {
            set_page_number(page.first);
            page.input.value = page.first;
            page.prev.classList.add('disabled');
            if (page.last == page.first) {
              page.next.classList.add('disabled');
            }
          } else if (page.number >= page.last) {
            set_page_number(page.last);
            page.input.value = page.last;
            page.next.classList.add('disabled');
            if (page.first == page.last) {
              page.prev.classList.add('disabled');
            }
          }
          if (within_limits(page.number) && page.number != this.page.current) {
            this.page.current = page.number;
            this.ModalQueryOrders();
          }
        }
      },
      SetPageJumpWidth() {
        const current = document.querySelector('.page-number-current');
        const last = document.querySelector('.page-number-last');
        if (current && last) {
          const rect = last.getBoundingClientRect();
          const input = current.querySelector('input');
          if (rect && rect.width) {
            current.style.setProperty('width', rect.width + 'px');
          } else {
            current.style.removeProperty('width');
          }
          if (input) {
            input.dispatchEvent(new Event('blur'));
          }
        }
      },
      ModalPageController(next_page) {
        let load_page;
        const page = {};
        const modal_page = this.modal.create.page;
        document.querySelectorAll('.modal .page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        if (next_page) {
          if (modal_page.current != modal_page.last) {
            modal_page.current++;
            load_page = true;
            if (modal_page.current == modal_page.last) {
              page.next.classList.add('disabled');
            }
            page.prev.classList.remove('disabled');
          }
        } else {
          if (modal_page.current > 1) {
            modal_page.current--;
            load_page = true;
            if (modal_page.current == 1) {
              page.prev.classList.add('disabled');
            }
            page.next.classList.remove('disabled');
          }
        }
        if (load_page) {
          this.ModalQueryOrders();
        }
      },
      ModalPageNavigator(e) {
        const page = {
          event: e.type,
          input: e.target,
          last: Number(e.target.max),
          first: Number(e.target.min),
          number: Number(e.target.value)
        }
        const within_limits = (n) => {
          return n >= page.first && n <= page.last;
        }
        const set_page_number = (n) => {
          this.page.number = n || page.number;
          page.number = Number(this.page.number);
        }
        const modal_page = this.modal.create.page;
        document.querySelectorAll('.modal .page-turn').forEach(arrow => {
          if (arrow) page[arrow.classList.contains('prev') ? 'prev' : 'next'] = arrow;
        });
        set_page_number();
        if (page.event == 'keydown') {
          if (e.key == 'Enter') {
            page.input.blur();
          }
        }
        if (page.event == 'blur') {
          ['prev', 'next'].map(arrow => {
            page[arrow] && page[arrow].classList.remove('disabled');
          });
          if (page.number <= page.first) {
            set_page_number(page.first);
            page.input.value = page.first;
            page.prev.classList.add('disabled');
            if (page.last == page.first) {
              page.next.classList.add('disabled');
            }
          } else if (page.number >= page.last) {
            set_page_number(page.last);
            page.input.value = page.last;
            page.next.classList.add('disabled');
            if (page.first == page.last) {
              page.prev.classList.add('disabled');
            }
          }
          if (within_limits(page.number) && page.number != modal_page.current) {
            modal_page.current = page.number;
            this.ModalQueryOrders();
          }
        }
      },
      ModalSetPageJumpWidth() {
        const current = document.querySelector('.modal .page-number-current');
        const last = document.querySelector('.modal .page-number-last');
        if (current && last) {
          const rect = last.getBoundingClientRect();
          const input = current.querySelector('input');
          if (rect && rect.width) {
            current.style.setProperty('width', rect.width + 'px');
          } else {
            current.style.removeProperty('width');
          }
          if (input) {
            input.dispatchEvent(new Event('blur'));
          }
        }
      },
      async ResetBulk() {
        return await new Promise(resolve => {
          let bulk = this.modal.create.bulk;
          let select = bulk.select;
          select.visible = ['select_all', 'select_page'];
          select.items = {all: [], checked: [], unchecked: []};
          select.all = false;
          resolve(true);
        });
      },
      SelectedItems() {
        let items = this.modal.create.bulk.select.items;
        let all = items.all.length - items.unchecked.length;
        let checked = items.checked.length;
        return (all < 0 ? 0 : all) || (checked < 0 ? 0 : checked);
      },
      HandleDropdownTrigger(event) {
        let trigger = event.target;
        if (trigger.checked) {
          let mousedown = (e) => {
            if (!trigger.parentElement.contains(e.target)) {
              window.removeEventListener('mousedown', mousedown);
              trigger.checked = false;
            }
          }
          window.addEventListener('mousedown', mousedown, false);
        }
      },
      HandleDropdownOption(option) {
        let create = this.modal.create;
        let select = create.bulk.select;
        let items = select.items;
        let list = create.list;
        switch (option.code) {
          case 'select_all': {
            let index = select.visible.indexOf(option.code);
            if (index > -1) select.visible[index] = 'deselect_all';
            index = select.visible.indexOf('select_page');
            if (index > -1) select.visible.splice(index, 1);
            select.visible = [...select.visible, ...['deselect_page']];
            select.items.unchecked = [];
            for (let item of list.items) {
              if (!select.items.checked.includes(item.id)) {
                for (let i = 0; i < select.items.checked.length; i++) {
                  if (!items.checked[i]) {
                    select.items.checked.splice(i, 1);
                    break;
                  }
                }
                select.items.checked.push(item.id);
              }
              item.checked = true;
            }
            select.items.all.length = create.entries;
            select.items.checked.length = create.entries;
            select.all = true;
            break;
          }
          case 'deselect_all': {
            select.items.checked = [];
            select.visible = ['select_all', 'select_page'];
            select.items.unchecked = [];
            for (let item of list.items) {
              item.checked = false;
            }
            select.items.all = [];
            select.all = false;
            break;
          }
          case 'select_page': {
            let index = select.visible.indexOf(option.code);
            if (index > -1) select.visible[index] = 'deselect_page';
            if (!select.visible.includes('deselect_all')) {
              select.visible.splice(1, 0, 'deselect_all');
            }
            for (let item of list.items) {
              if (!select.items.checked.includes(item.id)) {
                select.items.checked.push(item.id);
              }
              let index = select.items.unchecked.indexOf(item.id);
              if (index > -1) select.items.unchecked.splice(index, 1);
              item.checked = true;
            }
            if (select.items.checked.length == create.entries || select.all) {
              index = select.visible.indexOf('select_all');
              if (index > -1) select.visible.splice(index, 1);
              select.all = true;
            }
            break;
          }
          case 'deselect_page': {
            let index = select.visible.indexOf(option.code);
            if (index > -1) select.visible[index] = 'select_page';
            if (!select.visible.includes('select_all')) {
              select.visible.splice(1, 0, 'select_all');
            }
            for (let item of list.items) {
              if (!select.items.unchecked.includes(item.id)) {
                select.items.unchecked.push(item.id);
              }
              let index = select.items.checked.indexOf(item.id);
              if (index > -1) select.items.checked.splice(index, 1);
              item.checked = false;
            }
            if (!this.SelectedItems()) {
              this.HandleDropdownOption({code: 'deselect_all'});
            }
          }
        }
        select.visible = [...new Set(select.visible)];
        //console.log(this.CloneObject(select))
      },
      ToggleCheckbox(event, item) {
        let checkbox = event.target;
        let checked = checkbox.checked;
        let create = this.modal.create;
        let select = create.bulk.select;
        let list = create.list;
        if (checked) {
          let index = select.items.unchecked.indexOf(item.id);
          if (index > -1) select.items.unchecked.splice(index, 1);
          if (!select.items.checked.includes(item.id)) {
            select.items.checked.push(item.id);
          }
          for (let code of ['deselect_all', 'deselect_page']) {
            if (!select.visible.includes(code)) {
              select.visible.push(code);
            }
          }
          if (select.items.checked.length == create.entries || select.all) {
            index = select.visible.indexOf('select_all');
            if (index > -1) select.visible.splice(index, 1);
            select.all = true;
          }
          if (!list.items.some(item => !item.checked)) {
            let index = select.visible.indexOf('select_page');
            if (index > -1) select.visible.splice(index, 1);
          }
          item.checked = true;
        } else {
          let index = select.items.checked.indexOf(item.id);
          if (index > -1) select.items.checked.splice(index, 1);
          if (!select.items.unchecked.includes(item.id)) {
            select.items.unchecked.push(item.id);
          }
          for (let code of ['select_all', 'select_page']) {
            if (!select.visible.includes(code)) {
              select.visible.push(code);
            }
          }
          if (!list.items.some(item => item.checked)) {
            let index = select.visible.indexOf('deselect_page');
            if (index > -1) select.visible.splice(index, 1);
          }
          item.checked = false;
        }
        if (!select.items.checked.length) {
          for (let code of ['deselect_all', 'deselect_page']) {
            let index = select.visible.indexOf(code), splice = true;
            //if (code == 'deselect_all') splice = select.all;
            if (index > -1 && splice) select.visible.splice(index, 1);
          }
        }
        select.visible = [...new Set(select.visible)];
        for (let type of ['checked', 'unchecked']) {
          select.items[type].sort();
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .button {
    &-group {
      display: flex;
      margin-left: 30px;
      align-items: flex-end;
      justify-content: flex-end;

      .separator {
        border-width: 0;
        border-style: dashed;
        border-color: #eaeaea;

        &.left {
          padding-left: 30px;
          border-left-width: 2px;
        }

        &.right {
          padding-right: 30px;
          border-right-width: 2px;
        }
      }

      * {
        min-width: 100px;

        & ~ * {
          margin-left: 30px;
        }
      }
    }
  }

  tbody:not(:empty) {
    &::after {
      width: 100%;
      height: 1px;
      bottom: 0;
      //content: '';
      display: table-row;
      position: sticky;
      background: #cccccc;
    }
  }

  .item-state-select ~ .v-wrapper-input {
    border: 0;
  }

  .modal-footer {
    align-items: center;

    .entries {
      height: 34px;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      user-select: none;
      cursor: default;
      border-radius: 4px;
      background: #f0f0f1;
      padding: 0 10px;
    }
  }
</style>
